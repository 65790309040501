
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300&family=Oswald&display=swap');


body {
    font-family: 'Oswald', sans-serif;
    min-width: 320px !important;
}

.dp {
    position: relative;
    z-index: 2;
    max-height: 350px;
    border-radius: 200%;
    left: 6px;
}


.image-box {
    position: relative;
    float: left;
}


.topBoxDark {
    padding: 20px;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
    background-color: #1c0203;
    overflow: hidden;
}

.footer-image {
    position: absolute;
    height: 392px;
    z-index: 1;
    right: 0;
    left: 0;
}

.random1 {
    position: absolute;
    z-index: -1;
    height: 31vh;
    top: 0;
    right: 0;
}

.random2 {
    position: absolute;
    z-index: -1;
    height: 31vh;
    left: 0;
    top: 50%;
}

.random3 {
    position: absolute;
    z-index: -1;
    height: 200px;
    left: 53%;
    top: 73%;
}

.mainBody {
    padding: 40px 250px;
    background-color: #02021f;
}

.title {
    display: inline-block;
    width: 40%;
    margin: 15px 50px;
}

.name {
    font-size: 2.5em;
    color: white;
}

.subtitle {
    color: white;
    font-size: 1.1em;
    text-align: justify;
}

.middle-container-image {
    text-align: center;
}

.topic-container {
    position: relative;
    margin-top: 40px;
    background-color: #1c0203;
}

@media only screen and (max-width : 1366px) {
    .mainBody {
        padding: 40px 100px;
    }

    .footer-image {
        height: 364px;
    }

    .dp {
        max-height: 324px;
    }
}


@media only screen and (max-width : 1200px) {
    .mainBody {
        padding: 40px 100px;
    }

    .footer-image {
        height: 342px;
    }

    .dp {
        max-height: 306px;
    }
}

@media only screen and (max-width : 920px) {
    .mainBody {
        padding: 42px 50px;
    }

    .footer-image {
        height: 280px;
    }

    .dp {
        max-height: 250px;
    }

    .random3 {
        top: 110%;
    }
}

@media only screen and (max-width : 768px) {
    .mainBody {
        padding: 0;
        text-align: center;
    }

    .footer-image {
        height: 280px;
    }

    .dp {
        max-height: 250px;
    }

    .title {
        width: 100%;
        margin: 0px;
    }

    .image-box {
        float: none;
        width: 250px;
        margin: auto;
    }

    .random3 {
        left: 60%;
        bottom: -66%;
        top: initial;
    }

    .random2 {
        bottom: -30%;
        top: initial;
        left: -20px;
    }

    .random1 {
        z-index: 0;
        top: -57px;
        right: 0px;
        height: 23vh;
    }

    .topic-container {
        margin-top: 50px;
    }

    .topBoxDark{
        box-shadow: none;
    }

    .topBoxWhite{
        box-shadow: none;
    }
}


@media only screen and (max-width : 460px) {
    .mainBody {
        padding: 0;
        text-align: center;
        background-color: #1c0203;
    }
    .random3 {
        left: 45%;
        bottom: -96%;
        top: initial;
    }

    .random2 {
        bottom: -25%;
        top: initial;
    }

    .random1 {
        z-index: 0;
        top: -57px;
        right: 0px;
        height: 23vh;

    }

    .aboutUs-bg {
        flex-direction: column;
      }
    .box-container {
        margin-bottom: 20px;
    }
}

@media only screen and (max-width : 320px) {
    .random3 {
        left: 40%;
        bottom: -110%;
        top: initial;
    }

    .random2 {
        bottom: -25%;
        top: initial;
    }

    .random1 {
        z-index: 0;
        top: -57px;
        right: 0px;
        height: 23vh;
    }
}

.social-links-icons {
    height: 25px;
    margin: 10px 5px;
}

.dark-mode-icons {
    height: 32px;
    margin: 0px 9px;
}

.links-social {
    display: inline-block;
    position: relative;
    z-index: 1;
}

.aboutUs-bg {
    background-color: #1c0203;
    margin-top: 20px;
    padding:48px;
    display: flex;
    justify-content: space-between;
    
}

.box-container {
    flex: 1 1 0px;
    padding: 20px;
    text-align: center;
}

.paper-icon {
    margin-top: -40px;
    margin-left: 10px;
    width: 34px;
    height: 22px;
    border-bottom: none;
    border-left: 6px solid;
    border-right: 6px solid;
    border-top: none;
    position: relative;
    z-index: 5;
}

.anchor-links {
    text-decoration: none;
    color: black;
    background: linear-gradient(to right, #fff 25%, #2d9687 0, #2d9687 50%, #e9b171 0, #e9b171 75%, #e2b8aa 0);
    background-size: 500%; /* Set background size to twice the width */
    transition: background-position 0.3s ease; /* Add a smooth transition to background position */
    display: inline-block;
}

.anchor-links:hover {
    background-position: -100% 0
}

.explore a {
    color: #0e2a47;
    text-decoration: underline overline;
    text-underline-offset: 5px;
}

.explore a:hover {
    color: #3498db;
}
  
  .progress {
    background: rgba(255,255,255,0.1);
    justify-content: flex-start;
    border-radius: 100px;
    align-items: center;
    position: relative;
    display: flex;
    height: 10px;
  }
  
  .progress-value {
    animation: load 4s normal forwards;
    box-shadow: 0 10px 40px -10px #fff;
    border-radius: 100px;
    background: #38543a;
    height: 10px;
    width: 0;
  }
  
  @keyframes load {
    0% { width: 0; }
    100% { width: var(--my-var); }
  }

  .anchor-links2 {
    cursor: pointer;
    color: cornflowerblue;
    text-decoration: underline;
}